import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [scores, setScores] = useState([]);

  useEffect(() => {
    axios.get('https://epic.xeedev.com/api/scores')
      .then(response => {
        // Sort and limit the results to the top 20
        const sortedScores = response.data.sort((a, b) => b.Score - a.Score).slice(0, 20);
        setScores(sortedScores);
      })
      .catch(error => console.log(error));
  }, []);

  // Helper function to format position
  const formatPosition = (index) => {
    const suffix = ['th', 'st', 'nd', 'rd'];
    const v = index % 100;
    return index + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
  };

  // Helper function to truncate address
  const truncateAddress = (address) => {
    return `${address.substring(0, 5)}...`;
  };

  return (
    <div className="App min-h-screen bg-dark p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Leaderboard</h1>
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 rounded shadow-md flex flex-wrap justify-between items-center text-center">
          <p className="text-white flex-1 text-3xl">Position</p>
          <p className="text-white flex-1 text-3xl">Address</p>
          <p className="text-white flex-1 text-3xl">Name</p>
          <p className="text-white flex-1 text-3xl">Score</p>
        </div>
        {scores.map((score, index) => (
          <div key={index} className="p-4 rounded shadow-md flex flex-wrap justify-between items-center text-center border-b-2 border-purple-800">
            <p className="text-white flex-1 text-3xl">{formatPosition(index + 1)}</p>
            <p className="text-white flex-1 text-3xl"> {truncateAddress(score.WalletAddress)}</p>
            <p className="text-white flex-1 text-3xl"> {score.Name}</p>
            <p className="score flex-1 text-3xl"> {score.Score}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
